<template>
  <b-card>
    <ol
      class="c3 lst-kix_list_1-0 start"
      start="1"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Tambah Pengguna</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_5-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu Manajemen Akun Pengguna</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c0">Klik tombol Tambah Pengguna</span>
      </li>
    </ul>
    <p class="c11">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 335.34px;">
        <b-img
          alt="D:\user guide\manajemen akun\1.png"
          :src="require('@/assets/images/images-3/image19.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_5-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Isi semua data pengguna yang benar, kemudian klik tombol </span><span class="c7">Simpan</span>
        <b-img
          alt="D:\user guide\manajemen akun\2.png"
          :src="require('@/assets/images/images-3/image7.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <p class="c5">
      <span class="c7" />
    </p>
    <p class="c5">
      <span class="c7" />
    </p>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_5-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Jika muncul notifikasi seperti di bawah, maka pengguna berhasil ditambahkan</span><span class="c7"><br></span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 335.05px;">
          <b-img
            alt="D:\user guide\manajemen akun\3.png"
            :src="require('@/assets/images/images-3/image13.png')"
            style="width: 624.00px; height: 335.05px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
        </span>
      </li>
    </ul>
    <ol
      class="c3 lst-kix_list_1-0"
      start="2"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Tambah Divisi</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_6-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu Manajemen Akun Divisi</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik tombol Tambah Divisi</span>
        <b-img
          alt="D:\user guide\tambah divisi\1.png"
          :src="require('@/assets/images/images-3/image6.png')"
          style="width: 624.00px; height: 334.25px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_6-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Isi semua data divisi yang benar, kemudian klik tombol Simpan</span>
        <b-img
          alt="D:\user guide\tambah divisi\2.png"
          :src="require('@/assets/images/images-3/image17.png')"
          style="width: 624.00px; height: 335.87px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_6-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Divisi yang baru ditambahkan otomatis muncul di posisi teratas, seperti gambar di bawah</span>
        <b-img
          alt="D:\user guide\tambah divisi\3.png"
          :src="require('@/assets/images/images-3/image14.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c2 c10">
      <span class="c0" />
    </p>
    <p class="c2">
      <span class="c0" />
    </p>
    <ol
      class="c3 lst-kix_list_1-0"
      start="3"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Edit Divisi</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_7-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu Manajemen Akun Divisi</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik tombol berbentuk titik 3 seperti di gambar untuk meng-edit divisi</span>
        <b-img
          alt="D:\user guide\edit divisi\1.png"
          :src="require('@/assets/images/images-3/image20.png')"
          style="width: 624.00px; height: 334.15px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_7-0">
      <li class="c4 li-bullet-0">
        <span class="c0">Masukkan data divisi yang baru, kemudian klik tombol Simpan</span>
        <b-img
          alt="D:\user guide\edit divisi\2.png"
          :src="require('@/assets/images/images-3/image10.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c2">
      <span class="c0" />
    </p>
    <p class="c2">
      <span class="c0" />
    </p>
    <ol
      class="c3 lst-kix_list_1-0"
      start="4"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Tambah Group</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_8-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu Manajemen Akun &nbsp;-&gt; Group</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik tombol Tambah Group</span>
        <b-img
          alt="D:\user guide\tambah group\1.png"
          :src="require('@/assets/images/images-3/image8.png')"
          style="width: 624.00px; height: 335.59px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_8-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Masukkan data group yang benar, kemudian klik tombol Simpan</span>
        <b-img
          alt="D:\user guide\tambah group\2.png"
          :src="require('@/assets/images/images-3/image4.png')"
          style="width: 624.00px; height: 336.68px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c7" />
    </p>
    <ul class="c3 lst-kix_list_8-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Group yang baru ditambahkan otomatis muncul di posisi teratas, seperti gambar di bawah</span>
        <b-img
          alt="D:\user guide\tambah group\3.png"
          :src="require('@/assets/images/images-3/image1.png')"
          style="width: 624.00px; height: 334.79px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ol
      class="c3 lst-kix_list_1-0"
      start="5"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Edit Group</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_9-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Klik menu </span><span class="c7">Manajemen Akun -&gt; Group</span>
      </li>
      <li class="c1 li-bullet-0">
        <span class="c0">Klik tombol berbentuk titik 3 seperti di gambar untuk meng-edit group</span>
        <b-img
          alt="D:\user guide\edit group\1.png"
          :src="require('@/assets/images/images-3/image16.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_9-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Masukkan data group yang baru secara benar, kemudian klik tombol Simpan</span>
        <b-img
          alt="D:\user guide\edit group\2.png"
          :src="require('@/assets/images/images-3/image11.png')"
          style="width: 624.00px; height: 335.59px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c5">
      <span class="c0" />
    </p>
    <ol
      class="c3 lst-kix_list_1-0"
      start="6"
    >
      <li class="c8 li-bullet-0">
        <span class="c0">Lupa Password</span>
      </li>
    </ol>
    <ul class="c3 lst-kix_list_10-0 start">
      <li class="c1 li-bullet-0">
        <span class="c0">Pada halaman login, klik tulisan </span><span class="c7">Lupa Password?</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c0">Masukkan email anda, kemudian klik tombol </span><span class="c7">Kirim</span>
        <b-img
          alt="D:\user guide\lupa password\2.png"
          :src="require('@/assets/images/images-3/image3.png')"
          style="width: 624.00px; height: 335.34px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </li>
    </ul>
    <p class="c2">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_10-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Selanjutnya cek inbox di email anda, jika tidak ada coba cek di spam. Kemudian klik </span><span class="c7">RESET PASSWORD</span>
      </li>
    </ul>
    <p class="c9">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 294.80px;">
        <b-img
          alt="D:\user guide\lupa password\3.png"
          :src="require('@/assets/images/images-3/image12.png')"
          style="width: 624.00px; height: 294.80px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c5">
      <span class="c0" />
    </p>
    <ul class="c3 lst-kix_list_10-0">
      <li class="c1 li-bullet-0">
        <span class="c0">Isi password anda yang baru, kemudian klik tombol </span><span class="c7">Set Password Baru</span>
      </li>
      <li class="c4 li-bullet-0">
        <span class="c0">Jika muncul notifikasi seperti di bawah, penggantian password telah berhasil dan anda bisa memakai password yang baru untuk login</span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 624.00px; height: 335.59px;">
          <b-img
            alt="D:\user guide\lupa password\5.png"
            :src="require('@/assets/images/images-3/image5.png')"
            style="width: 624.00px; height: 335.59px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
        </span>
      </li>
    </ul>
    <p class="c2">
      <span class="c0" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
